<template>
  <div>
    <el-button class="_ImgBtn" type="primary" @click="getPdf()">
      <img src="../../assets/images/import1.png" style="margin-right: 5px" />
      导出PDF
    </el-button>
    <el-table :data="tableData" style="width: 100%" class="table" id="pdfDom">
      <el-table-column prop="" label="审核计划"></el-table-column>
      <el-table-column prop="" label="审核目的"></el-table-column>
      <el-table-column prop="" label="审核范围"></el-table-column>
      <el-table-column prop="" label="审核依据"></el-table-column>
      <el-table-column prop="" label="审核组长"></el-table-column>
      <el-table-column prop="" label="审核员"></el-table-column>
      <el-table-column prop="" label="审核日期"></el-table-column>
      <el-table-column prop="" label="审核情况"></el-table-column>
      <el-table-column prop="" label="审核结论"></el-table-column>
      <el-table-column prop="" label="编制"></el-table-column>
      <el-table-column prop="" label="编制日期"></el-table-column>
      <el-table-column prop="" label="审核"></el-table-column>
      <el-table-column prop="" label="审核日期"></el-table-column>
      <el-table-column prop="" label="批准"></el-table-column>
      <el-table-column prop="" label="批准日期"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      htmlTitle: "体系审核报告",
      tableData:[],
    };
  },
};
</script>

<style lang="less" scoped>
.table{
  margin-top: 12px;
}
</style>